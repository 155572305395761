<template>
  <div>
    <TheTeCommonHeaderSimpleCatchcopy>
      <template #catchcopy>
        <span class="block md:inline">通過率10％を突破した</span>
        <span class="block md:inline">現役Webデザイナーがマンツーマンで学習サポート</span>
      </template>
    </TheTeCommonHeaderSimpleCatchcopy>
    <main>
      <slot />
    </main>
    <TheTeCommonFooter />
  </div>
</template>

<script setup lang="ts">
useHead({
  htmlAttrs: {
    "data-theme": 'techacademy',
  },
})
</script>

<script lang="ts">
import { defineNuxtComponent } from '#app'
import TheTeCommonHeaderSimpleCatchcopy from '~/components/Te/TeCommon/Header/TheTeCommonHeaderSimpleCatchcopy/TheTeCommonHeaderSimpleCatchcopy.vue'
import TheTeCommonFooter from '~/components/Te/TeCommon/Footer/TheTeCommonFooter/TheTeCommonFooter.vue'

const options = {
  components: {
    TheTeCommonHeaderSimpleCatchcopy,
    TheTeCommonFooter,
  },
}

export default defineNuxtComponent(options)
</script>
